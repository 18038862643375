import { faIdBadge, faMicrochip, faSyncAlt, faTimesCircle, faUser } from "@fortawesome/free-solid-svg-icons";
import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import axios from "axios";
import clsx from 'clsx';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import ChipDetailList from "../../components/chip-detail-list";
import Empty from '../../components/empty';
import Title from '../../components/title';
import { AuthContext } from '../../context/authContext';
import { EventsFilterContext } from "../../context/eventsFIlterContext";
import { LocationContext } from '../../context/locationContext';
import api from '../../service/api';
import { getControllers } from '../../service/controllersApi';
import { getEventList, getEventListCount, getEventsMonthlyReport, getEventsYearlyReport } from "../../service/eventsApi";
import { request } from '../../service/requests';
import { getUnsyncedDataCount } from "../../service/unsyncedControllerApi";
import { GetAllUsers } from "../../service/usersApi";
import colors from '../../theme/colors';
import { ACCESS_CHECK, ACCESS_DENIED, ACCESS_GRANTED, ALL_TYPES, API_REQUEST_ERROR_MESSAGE, ASCENDING, AVAILABLE_CREDENTIALS, CONTROLLERS, CONTROLLERS_MODULE, CONTROLLER_OFFLINE, CREDENTIALS, CREDENTIALS_MODULE, DASHBOARD_CHIP, DATE, DATETIME_FORMAT, DATE_CREATED, DATE_FORMAT_YYYY_MM_DD, DAY, DAY_FORMAT, DESCENDING, EVENTS, EVENTS_MODULE, FILTER_EVENT_FORMAT, GET, GRANTED, INVALID_CREDENTIALS, MONTH, MONTHS, MONTH_FORMAT, NAME, OFF, OFFLINE, OPEN_SYNC_COMMANDS, THIS_MONTH, THIS_WEEK, THIS_YEAR, TIME, TODAY, TODAY_FORMAT, USERS_MODULE, USER_INVALID_DATE, WEEK, WSTOPIC, YEAR, YEAR_FORMAT } from '../../utility/constants';
import { formatDate, generateURLQueryParam } from "../../utility/helper";
import Error401 from "../error-401";
import ChipContainer from './dashboad-chip-container';
import DropDown from './dashboard-dropdown';
import EventsChart from './dashboard-events-charts';
import EventsContainer from './dashboard-events-table';
import { ChipsSkeleton, EventsContainerSkeleton } from './dashboard-skeleton';
import useStyles from './styles';
import { createChips, typeOption } from './utils';

const controllerChips = createChips(CONTROLLER_OFFLINE, faMicrochip);
const openSyncChips = createChips(OPEN_SYNC_COMMANDS, faSyncAlt, api.OPEN_SYNC_COMMANDS, '', CONTROLLERS);
const availableCredsChips = createChips(AVAILABLE_CREDENTIALS, faIdBadge, api.CREDENTIALS_ACTIVE, CREDENTIALS, CREDENTIALS);
const invalidPersonsChips = createChips(USER_INVALID_DATE, faUser, '', '', '');
const invalidCredsChips = createChips(INVALID_CREDENTIALS, faIdBadge, api.CREDENTIALS_INVALID, DATE, CREDENTIALS);
const accessDeniedChips = createChips(ACCESS_DENIED, faTimesCircle, api.EVENTS, '', );

const dropDownEvents = [TODAY, THIS_WEEK, THIS_MONTH, THIS_YEAR];

const ChartContainer = (props) => {
  const { title, charts, name, containerType, id } = props
  const classes = useStyles();
  return (
    <Container className={classes.chartContainer}>
      <Paper id={id} className={clsx(containerType === 'DashboardTablePanel' ? classes.panelTablePaper : classes.paper)}>
        <Box className={classes.chartName}>
          {title}
        </Box>
        <Box className={clsx(containerType === 'DashboardTablePanel' ? classes.panelTableBox : (name && classes.charts))}>{charts ? charts : ''}</Box>
      </Paper>
    </Container>
  )
}

const DashboardChips = (props) => {
  const { controllerStatus, openSyncStatus, availableCredStatus, invalidPersonStatus, invalidCredStatus, accessDeniedStatus, activeChip, handleClickToDetails, hasControllerPermission, hasCredentialPermission, hasEventPermission, hasPersonPermission } = props;
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item 
        onClick={() => handleClickToDetails(controllerStatus?.name, controllerStatus?.count)} 
        className={clsx({
            [classes.moduleContainer] : hasControllerPermission && controllerStatus > 0,
            'hidden'                  : !hasControllerPermission      
          })
        }
        lg={6} md={12} sm={6} xs={12}>
        {
          controllerStatus.count >= 0 ?
            <ChipContainer
              icon={controllerStatus.icon}
              name={controllerStatus.name}
              count={controllerStatus.count}
              selected={activeChip}
            />
          :
            <ChipsSkeleton/>
        }
      </Grid>
      <Grid item
        onClick={() => handleClickToDetails(openSyncStatus.name, openSyncStatus.count)}
        className={clsx({
            [classes.moduleContainer] : hasControllerPermission && openSyncStatus.count > 0,
            'hidden'                  : !hasControllerPermission
          })
        }
        lg={6} md={12} sm={6} xs={12}>
        {
          openSyncStatus.count >= 0 ?
            <ChipContainer
              icon={openSyncStatus.icon}
              name={openSyncStatus.name}
              count={openSyncStatus.count}
              selected={activeChip}
            />
          :
            <ChipsSkeleton/>
        }
      </Grid>
      <Grid item
        onClick={() => handleClickToDetails(availableCredStatus.name, availableCredStatus.count)}
        className={clsx({
            [classes.moduleContainer] : hasCredentialPermission && availableCredStatus.count > 0,
            'hidden'                  : !hasCredentialPermission
          })
        }
        lg={6} md={12} sm={6} xs={12}>
        {
          availableCredStatus.count >= 0 ?
            <ChipContainer
              icon={availableCredStatus.icon}
              name={availableCredStatus.name}
              count={availableCredStatus.count}
              selected={activeChip}
            />
          :
            <ChipsSkeleton/>
        }
      </Grid>
      <Grid item
        onClick={() => handleClickToDetails(invalidPersonStatus.name, invalidPersonStatus.count)}
        className={clsx({
            [classes.moduleContainer] : hasPersonPermission && invalidPersonStatus.count > 0,
            'hidden'                  : !hasPersonPermission
          })
        }
        lg={6} md={12} sm={6} xs={12}>
        {
          invalidPersonStatus.count >= 0 ?
            <ChipContainer
              icon={invalidPersonStatus.icon}
              name={invalidPersonStatus.name}
              count={invalidPersonStatus.count}
              selected={activeChip}
            />
          :
            <ChipsSkeleton/>
        }
      </Grid>
      <Grid item
        onClick={() => handleClickToDetails(invalidCredStatus.name, invalidCredStatus.count)}
        className={clsx({
            [classes.moduleContainer] : hasCredentialPermission && invalidCredStatus.count > 0,
            'hidden'                  : !hasCredentialPermission
          })
        }
        lg={6} md={12} sm={6} xs={12}>
        {
          invalidCredStatus.count >= 0 ?
            <ChipContainer
              icon={invalidCredStatus.icon}
              name={invalidCredStatus.name}
              count={invalidCredStatus.count}
              selected={activeChip}
            />
          :
            <ChipsSkeleton/>
        }
      </Grid>
      <Grid item
        onClick={() => handleClickToDetails(accessDeniedStatus.name, accessDeniedStatus.count)}
        className={clsx({
            [classes.moduleContainer] : hasEventPermission && accessDeniedStatus.count > 0,
            'hidden'                  : !hasEventPermission
          })
        }
        lg={6} md={12} sm={6} xs={12}>
        {
          accessDeniedStatus.count >= 0 ?
            <ChipContainer
              icon={accessDeniedStatus.icon}
              name={accessDeniedStatus.name}
              count={accessDeniedStatus.count}
              selected={activeChip}
            />
          :
            <ChipsSkeleton/>
        }
      </Grid>
    </Grid>
  )

}

const Content = (props) => {
  const { chartData, activeChip, setActiveChip, events, handleEventRange, eventsFilter, typeFilter, handleTypeFilter, handleAccessFilter, accessFilter, eventsData, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, totalElement, tableIsLoading, tableContentIsLoading, eventIsLoading, eventContentIsLoading, thickWidth, controllerStatus, accessDeniedStatus, administrator, openSyncStatus, availableCredStatus, invalidPersonStatus, invalidCredStatus, hasControllerPermission, hasCredentialPermission, hasEventPermission, hasPersonPermission, showToaster } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const { state : stateFilter } = useContext(EventsFilterContext);

  const { state : locationState }       = useContext(LocationContext);
  const { selectedLocationIds }         = locationState;
  const [detailsName, setDetailsName]   = useState('');
  const [detailsCount, setDetailsCount] = useState('');

  useEffect(() => {
    setDetailsName('');
    setDetailsCount('');
  }, [selectedLocationIds]);

  const handleClickToDetails = (name, count) => {
    if (count > 0 && name === OPEN_SYNC_COMMANDS) {
      history.push('/controllers')
    }

    if (count > 0) {
      setDetailsName(name);
      setDetailsCount(count);
      setActiveChip(name);
    }
  }

  const handleBarClick = (barData) => {
    let dateFrom = '';
    let dateUntil = '';
    
    const selectedItem = chartData.find(item => barData.x === item.index);

    if (selectedItem) {
      let date = new Date(selectedItem.date);
      if (accessFilter === THIS_YEAR) {
        dateFrom = moment(date).startOf('month').format(FILTER_EVENT_FORMAT);
        dateUntil = moment(date).endOf('month').format(FILTER_EVENT_FORMAT);
      } else {
        dateFrom = moment(date).format(FILTER_EVENT_FORMAT);
        dateUntil = moment(date).format(FILTER_EVENT_FORMAT);
      }
    }

    const params  = {
      ...stateFilter,
      listType: GRANTED,
      types: [ACCESS_CHECK],
      subTypes: [ACCESS_GRANTED],
      from: dateFrom,
      until: dateUntil,
    }
    const queryParam = generateURLQueryParam(params);
    const location = {
      pathname : `${EVENTS}/access-granted`,
      search   : queryParam,
    }
    
    history.push(location);
  }

  useEffect(() => {
      if (invalidCredStatus.count > 0) {
        handleClickToDetails(DASHBOARD_CHIP.INVALID_CREDENTIAL_CHIP, invalidCredStatus.count);
      } else if (invalidPersonStatus.count > 0) {
        handleClickToDetails(USER_INVALID_DATE, invalidPersonStatus.count);
      } else if (availableCredStatus.count > 0) {
        handleClickToDetails(DASHBOARD_CHIP.AVAILABLE_CREDENTIAL_CHIP, availableCredStatus.count);
      } else if (accessDeniedStatus.count > 0) {
        handleClickToDetails(DASHBOARD_CHIP.ACCESS_DENIED_CHIP, accessDeniedStatus.count);
      } else if (controllerStatus.count > 0) {
        handleClickToDetails(CONTROLLER_OFFLINE, controllerStatus.count);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invalidCredStatus.count, invalidPersonStatus.count, availableCredStatus.count, accessDeniedStatus.count, controllerStatus.count, selectedLocationIds])

  const handleChipsPermission = (chipName) => {
    switch (chipName) {
      case USER_INVALID_DATE:
        return hasPersonPermission;
      case DASHBOARD_CHIP.AVAILABLE_CREDENTIAL_CHIP:
        return hasCredentialPermission;
      case DASHBOARD_CHIP.INVALID_CREDENTIAL_CHIP:
        return hasCredentialPermission;
      case CONTROLLER_OFFLINE:
        return hasControllerPermission;
      case DASHBOARD_CHIP.ACCESS_DENIED_CHIP:
        return hasEventPermission;
      default:
        return;
    }
  }

  return (
    <Grid container spacing={3} >
      <Grid item lg={12} md={12}>
        <Grid container spacing={3}>
          <Grid item lg={8} md={6}>
            <DashboardChips
              controllerStatus={controllerStatus}
              openSyncStatus={openSyncStatus}
              availableCredStatus={availableCredStatus}
              invalidPersonStatus={invalidPersonStatus}
              invalidCredStatus={invalidCredStatus}
              accessDeniedStatus={accessDeniedStatus}
              activeChip={activeChip}
              administrator={administrator}
              handleClickToDetails={handleClickToDetails}
              hasControllerPermission={hasControllerPermission}
              hasPersonPermission={hasPersonPermission}
              hasCredentialPermission={hasCredentialPermission}
              hasEventPermission={hasEventPermission}
            />
          </Grid>
          <Grid item lg={4} md={6}>
            <Grid container spacing={3} className={classes.chipDetailsContainer}>
              <Grid item className={clsx(!handleChipsPermission(activeChip) && 'hidden')} md={12} sm={12} lg={12} xs={12}>
                {detailsName ? <ChipDetailList detailsName={detailsName} detailsCount={detailsCount} showToaster={showToaster}/> : <></>}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={clsx(!hasEventPermission && 'hidden')} item md={12} sm={12} lg={6}>
        {
          tableIsLoading ?
            <EventsContainerSkeleton />
          :
            <ChartContainer
              id="dashboardEventsContainer"
              containerType='DashboardTablePanel'
              title={
                <Box className={classes.header}>
                  <Box className={classes.event}>
                    <DropDown id="dashboardEventsTypeFilter" items={typeOption} value={typeFilter} handleChange={handleTypeFilter}/>
                    <DropDown id="dashboardEventsRangeFilter" items={dropDownEvents} value={eventsFilter} handleChange={handleEventRange}/>
                  </Box>
                </Box>
              }
              charts={
                <EventsContainer
                  events={events}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  totalElement={totalElement}
                  tableContentIsLoading={tableContentIsLoading}
                />
              }
            />
        }
      </Grid>
      <Grid className={clsx(!hasEventPermission && 'hidden')} item md={12} sm={12} lg={6}>
        {
          eventIsLoading ?
            <EventsContainerSkeleton />
          :
            <ChartContainer
              id="dashboardChartContainer"
              title={
                <Box className={classes.header}>
                  <Typography>{t('access')}</Typography>
                  <DropDown id="dashboardChartRangeFilter" items={dropDownEvents} value={accessFilter} handleChange={handleAccessFilter}/>
                </Box>
              }
              charts={
                eventsData.some(item => item.data) ?
                  <EventsChart
                    eventData={eventsData}
                    thickWidth={thickWidth}
                    eventContentIsLoading={eventContentIsLoading}
                    handleBarClick={handleBarClick}
                  />
                :
                  <Box className={classes.empty}>
                    <Empty variant={'h6'}/>
                  </Box>
              }
            />
        }
      </Grid>
    </Grid>
  )
}

const Dashboard = (props) => {
  const { showToaster, handlePermissions, newMessage, setNewMessage, newTopic, setNewTopic } = props;
  const classes                                           = useStyles();
  const { t }                                             = useTranslation();

  const { state: authState }                              = useContext(AuthContext);
  const administrator                                     = authState.administrator;
  const {permissions}                                     = administrator.permissions;

  const { state : locationState }                         = useContext(LocationContext);
  const { selectedLocationIds }                           = locationState;
  const history                                           = useHistory();
  const [isLoading, setIsLoading]                         = useState(false);
  const [events, setEvents]                               = useState([]);
  const [eventsFilter, setEventsFilter]                   = useState(dropDownEvents[0]);
  const [typeFilter, setTypeFilter]                       = useState(typeOption[0]);
  const [accessFilter, setAccessFilter]                   = useState(dropDownEvents[0]);
  const [eventsData, setEventsData]                       = useState([]);
  const [page, setPage]                                   = useState(1);
  const [rowsPerPage, setRowsPerPage]                     = useState(10);
  const [totalElement, setTotalElement]                   = useState(0);
  const [tableIsLoading, setTableIsLoading]               = useState(true);
  const [tableContentIsLoading, setTableContentIsLoading] = useState(false);
  const [eventIsLoading, setEventIsLoading]               = useState(true);
  const [eventContentIsLoading, setEventContentIsLoading] = useState(false);
  const [thickWidth, setThickWidth]                       = useState(23);
  const [controllerStatus, setControllerStatus]           = useState(controllerChips);
  const [accessDeniedStatus, setAccessDeniedStatus]       = useState(accessDeniedChips);
  const [openSyncStatus, setOpenSyncStatus]               = useState([]);
  const [availableCredStatus, setAvailableCredsStatus]    = useState([]);
  const [invalidPersonStatus, setInvalidPersonStatus]     = useState([]);
  const [invalidCredStatus, setInvalidCredStatus]         = useState([]);
  const [activeChip, setActiveChip]                       = useState('');
  const [chartData, setChartData]                         = useState([]);

  const hasEventPermission      = handlePermissions(EVENTS_MODULE, GET);
  const hasControllerPermission = handlePermissions(CONTROLLERS_MODULE, GET);
  const hasCredentialPermission = handlePermissions(CREDENTIALS_MODULE, GET);
  const hasPersonPermission     = handlePermissions(USERS_MODULE, GET);

  let countCancelTokenRef = useRef(null);
  let listCancelTokenRef = useRef(null);
  let accessDeniedTokenRef = useRef(null);
  let accessGrantedTokenRef = useRef(null); 

  useEffect(() => {
    getInitialChips();
    setActiveChip('');
    setEvents([]);
  }, [selectedLocationIds]);

  const getAccessDeniedChipsData = useCallback(async () => {
    if (!hasEventPermission) {
      return;
    }

    const params = {
      types: ACCESS_CHECK,
      subTypes: ACCESS_DENIED,
      until: moment().format(FILTER_EVENT_FORMAT),
      locationIds: selectedLocationIds?.toString()
    };

    if (accessDeniedTokenRef.current) {
      accessDeniedTokenRef.current.cancel();
    }
    accessDeniedTokenRef.current = axios.CancelToken.source();

    try {
      const response = await getEventListCount(params, accessDeniedTokenRef);
      const totalElements = response.page.totalElements;

      setAccessDeniedStatus(prevAccessDeniedStatus => {
        return {
          ...prevAccessDeniedStatus,
          name: DASHBOARD_CHIP.ACCESS_DENIED_CHIP,
          count: totalElements,
        }
      });
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }, [hasEventPermission, showToaster, t, selectedLocationIds]);

  const getOpenSyncCommandData = useCallback(async (chip) => {
    if (!hasControllerPermission) {
      return;
    }
    
    try {
      return await getUnsyncedDataCount("", selectedLocationIds?.toString())
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }, [hasControllerPermission, showToaster, t, selectedLocationIds]);

  const getChipsData = useCallback(async (chip) => {
    try {
      const response = (chip.url !== api.OPEN_SYNC_COMMANDS) ?
         await request({
          url    : chip.url,
          method : GET,
          params : {
            ...chip.params,
            size : 1
          }
        })
      :
       await getOpenSyncCommandData(chip);
      
      const data = response ? response.data : 0;

      const chipsCount = data.page ? data.page.totalElements : data;
      return {
        name  : chip.name,
        icon  : chip.icon,
        count : chipsCount,
        data  : chip.data
      }
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }, [getOpenSyncCommandData, showToaster, t]);


  const getControllerStatus = useCallback(async () => {
    if (!hasControllerPermission) {
      return;
    }

    const params = {
      status      : OFFLINE,
      size        : 1,
      sort        : `${NAME},${ASCENDING}`,
      locationIds : selectedLocationIds?.toString()
    }

    try {
      const response = await getControllers(params);

      const { data } = response;
      const serialArray = data.controllers.map(item => item.serialNumber)
      const newControllerObj = {
        ...controllerChips,
        serialNumber : serialArray,
        count        : data.page.totalElements
      }
      
      setControllerStatus(newControllerObj);
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }, [hasControllerPermission, showToaster, t, selectedLocationIds]);

  const getInvalidUsers = useCallback(async(chip) => {
    const params = {
      validUntil          : moment(new Date()).format(DATE_FORMAT_YYYY_MM_DD),
      briefRepresentation : true
    }
    
    try {
      const response = await GetAllUsers(params);
      
      return {
        name  : chip.name,
        icon  : chip.icon,
        count : response.data.users.length,
        data  : chip.data
      }
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }, [t, showToaster]);

  const getChipsCount = useCallback(async () => {
    const openSyncChipsResponse       = hasControllerPermission && await getChipsData(openSyncChips);
    const invalidCredsChipsResponse   = hasCredentialPermission && await getChipsData(invalidCredsChips);
    const invalidPersonsChipsResponse = hasPersonPermission && await getInvalidUsers(invalidPersonsChips);
    const availableCredsChipsResponse = hasCredentialPermission && await getChipsData(availableCredsChips);

    setOpenSyncStatus(openSyncChipsResponse ?? handleDefaultChipsData(openSyncChips));
    setInvalidCredStatus(invalidCredsChipsResponse ?? handleDefaultChipsData(invalidCredsChips));
    setInvalidPersonStatus(invalidPersonsChipsResponse ?? handleDefaultChipsData(invalidPersonsChips));
    setAvailableCredsStatus(availableCredsChipsResponse ?? handleDefaultChipsData(availableCredsChips));

    await getAccessDeniedChipsData();
    await getControllerStatus();
  }, [getAccessDeniedChipsData, getChipsData, getControllerStatus, getInvalidUsers, hasControllerPermission, hasCredentialPermission, hasPersonPermission]);

  useEffect(() => {
    if (selectedLocationIds) {
      getChipsCount();
    }
  }, [selectedLocationIds, permissions, getChipsCount]);

  const getDateRange = useCallback((value) => {
    switch (value) {
      case THIS_WEEK:
        return rangeFormat(WEEK);
      case THIS_MONTH:
        return rangeFormat(MONTH);
      case THIS_YEAR:
        return rangeFormat(YEAR);
      default:
        return rangeFormat(DAY);
    }
  }, []);

  const getEventsCount = useCallback(async () => {
    const params = {
      ...getDateRange(eventsFilter),
      types       : typeFilter === ALL_TYPES ? '' : typeFilter,
      subType     : '',
      sort        : `${DATE_CREATED},${DESCENDING}`,
      locationIds : selectedLocationIds?.toString()
    }

    if (countCancelTokenRef.current) {
      countCancelTokenRef.current?.cancel();
    }
    countCancelTokenRef.current = axios.CancelToken.source();

    try {
      const response = await getEventListCount(params, countCancelTokenRef.current);
      const totalElements = response.page.totalElements;

      setTotalElement(totalElements);

    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    }
  }, [typeFilter, eventsFilter, getDateRange, selectedLocationIds, showToaster, t]);

  useEffect(() => {
    if (hasEventPermission && selectedLocationIds) {
      getEventsCount();
    }
  }, [typeFilter, eventsFilter, selectedLocationIds, permissions, getDateRange, showToaster, t, hasEventPermission, getEventsCount])

  const sortEvents = useCallback((eventA, eventB) => {
    const dateA = formatDate(eventA.dateCreated, DATETIME_FORMAT.EUROPEAN_DATE_TIME);
    const dateB = formatDate(eventB.dateCreated, DATETIME_FORMAT.EUROPEAN_DATE_TIME);
  
    if (dateA === dateB) {
      return eventA.eventId > eventB.eventId ? -1 : 1;
    }
  
    return dateA - dateB;
  }, []);

  const getEvents = useCallback(async () => {
    setTableContentIsLoading(true);

    const params = {
      ...getDateRange(eventsFilter),
      types       : typeFilter === ALL_TYPES ? '' : typeFilter,
      subType     : '',
      size        : rowsPerPage,
      page        : page,
      sort        : `${DATE_CREATED},${DESCENDING}`,
      locationIds : selectedLocationIds?.toString()
    }

    if (listCancelTokenRef.current) {
      listCancelTokenRef.current.cancel();
    }
    listCancelTokenRef.current = axios.CancelToken.source();

    try {
      const response = await getEventList(params, listCancelTokenRef.current);

      const eventData = response.events
        .sort((eventA, eventB) => sortEvents(eventA, eventB))
        .map(item => {
          return {
            id   : item.eventId,
            type : item.type,
            date : item.dateCreated
          }
        });

      setEvents(eventData);

    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setTableIsLoading(false);
      setTableContentIsLoading(false);
    }
  }, [eventsFilter, getDateRange, page, rowsPerPage, selectedLocationIds, showToaster, t, typeFilter, sortEvents]);

  useEffect(() => {
    if (hasEventPermission && selectedLocationIds) {
      getEvents();
    }
  }, [typeFilter, eventsFilter, rowsPerPage, page, selectedLocationIds, permissions, getDateRange, showToaster, t, hasEventPermission, getEvents])

  const getEventChartLabels = useCallback((count, filterToday, filterThisMonth) => {
    return Object.keys(count).map(value => {
      if (filterToday || filterThisMonth) {
        const translate = t(value.slice(0, -3));
        const itemValue = value.slice(-3);

        return `${translate}${itemValue}`;
      }
      return t(value)
    });
  }, [t]);

  const formatLabel = useCallback((format, current) => {
    const currentMonth = current.format('MMM');
    const dayFormat    = moment(current).format(DAY_FORMAT);
    const daysInMonth  = moment(current).daysInMonth();

    switch (format) {
      case THIS_WEEK:
        setThickWidth(65);
        return [...new Array(7)].map((_, index) => t(current.day(index).format(DAY_FORMAT)))
      case THIS_MONTH:
        setThickWidth(18);
        return [...new Array(daysInMonth)].map((_, index) => `${t(currentMonth)} ${('0'+ (index + 1)).slice(-2)}`)
      case THIS_YEAR:
        setThickWidth(40);
        return [...new Array(12)].map((_, index) => (t(moment().month(index).format(YEAR_FORMAT))))
      default:
        setThickWidth(23);
        return [...new Array(24)].map((_, index) => `${t(dayFormat)} ${('0'+ index).slice(-2)}`);
    }
  }, [t]);

  const getParams = useCallback((filter) => {
    switch (filter) {
      case THIS_MONTH:
        return {
          types       : ACCESS_CHECK,
          subTypes    : ACCESS_GRANTED,
          year        : moment().year(),
          month       : moment().format('M'),
          sort        : `${DAY}, ${ASCENDING}`,
          locationIds : selectedLocationIds?.toString()
        }
      case THIS_YEAR:
        return {
          types       : ACCESS_CHECK,
          subTypes    : ACCESS_GRANTED,
          year        : moment().year(),
          sort        : `${MONTH}, ${ASCENDING}`,
          locationIds : selectedLocationIds?.toString()
        }
      default:
        return {
          ...getDateRange(accessFilter),
          types       : ACCESS_CHECK,
          subTypes    : ACCESS_GRANTED,
          size        : 1000000,
          page        : 1,
          sort        : `${TIME}, ${ASCENDING}`,
          locationIds : selectedLocationIds?.toString()
        }
    }
  }, [accessFilter, getDateRange, selectedLocationIds]);

  const sortEventResults = useCallback((eventResults, filterToday, filterThisWeek, filterThisMonth) => {
    let sortedArray;
    if (filterToday || filterThisWeek) {
      const evenData = eventResults.map(item => { return item.dateCreated });
      sortedArray = evenData.sort((a,b) => moment(a).valueOf() - moment(b).valueOf());
    } else if (filterThisMonth) {
      sortedArray = eventResults.map((item, index, arr) => {
        const dateFormat = new Date(item.Year, item.Month, item.Day);
        return moment(dateFormat).subtract(1, MONTHS).format();
      });
    } else {
      sortedArray = eventResults.map((item, index, arr) => {
        return moment().month(item.Month).subtract(1, MONTHS).format();
      });
    }

    return sortedArray;
  }, []);

  const getEventCharts = useCallback(async () => {
    setEventContentIsLoading(true);

    const filterToday     = accessFilter === TODAY;
    const filterThisWeek  = accessFilter === THIS_WEEK;
    const filterThisMonth = accessFilter === THIS_MONTH;
    const filterThisYear  = accessFilter === THIS_YEAR;

    const params = getParams(accessFilter);
    
    try {
      let data = '';
      
      if (filterThisYear) {
        const response = await getEventsYearlyReport(params);
        data = response.data;
      } else if (filterThisMonth) {
        const response = await getEventsMonthlyReport(params);
        data = response.data;
      } else {
        if (accessGrantedTokenRef.current) {
          accessGrantedTokenRef.current.cancel();
        }
        accessGrantedTokenRef.current = axios.CancelToken.source();

        data = await getEventList(params, accessGrantedTokenRef.current);
      }

      const eventResults = getDataResults(accessFilter, data);
      const current = moment();

      const sortedArray = await sortEventResults(eventResults, filterToday, filterThisWeek, filterThisMonth).sort((a, b) => { return new Date(a) - new Date(b) });
      
      const formattedDates = sortedArray.map(item => formatDateCreated(accessFilter, item));
      const formattedData  = [...new Set(formattedDates)];
      const formattedLabel = formatLabel(accessFilter, current);

      let count = await countEventResults(eventResults, filterToday, filterThisWeek, filterThisMonth, formattedDates, formattedData);

      const labels = getEventChartLabels(count, filterToday, filterThisMonth);

      const countData = Object.values(count).map(value => value);
      let counter = 0;

      const eventData = formattedLabel.map((value, index) => {
        const label = filterToday ? `${value}:00`: value
        if (labels.find(dateLabel => value === dateLabel)) {
          counter++;
          return {
            label : label,
            data  : countData[counter - 1]
          }
        }
        return {
          label : label,
          data  : 0
        }
      });

      const newLabel = () => {
        if (filterToday) {
          return Object.values(eventData).map(value => value.label.slice(-5))
        }
        return Object.values(eventData).map(value => value.label);
      }
      
      const newCountData = Object.values(eventData).map(value => value.data);

      setEventsData([
        {
          label : newLabel(),
          data  : newCountData,
          name  : ACCESS_GRANTED,
          color : colors.SUCCESS
        },
      ]);

      const chartsData = sortedArray.map((date, index) => {
        return {
          date  : date,
          label : formattedDates[index],
          index : formattedLabel.findIndex(item => item === formattedDates[index])
        }
      });

      setChartData(chartsData);
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setEventIsLoading(false);
      setEventContentIsLoading(false);
    }
  }, [accessFilter, getEventChartLabels, formatLabel, getParams, showToaster, sortEventResults, t]);

  useEffect(() => {
    if (hasEventPermission && selectedLocationIds) {
      getEventCharts();
    }
  }, [hasEventPermission, selectedLocationIds, getEventCharts])

  const getInitialChips = () => {
    setControllerStatus(controllerChips);
    setAccessDeniedStatus(accessDeniedChips);
    setOpenSyncStatus(openSyncChips);
    setAvailableCredsStatus(availableCredsChips);
    setInvalidPersonStatus(invalidPersonsChips);
    setInvalidCredStatus(invalidCredsChips);
  }

  const handleDefaultChipsData = (chip) => {
    return {
      name  : chip.name,
      icon  : chip.icon,
      count : 0,
      data  : chip.data
    }
  }

  const rangeFormat = (value) => {
    const current = moment()
    return {
      from  : current.startOf(value).format(FILTER_EVENT_FORMAT),
      until : current.add(1, value).startOf(value).format(FILTER_EVENT_FORMAT)
    }
  }

  const formatDateCreated = (format, value) => {
    const dateTime = value.replace(/[T]/g, ' ').replace(/[Z]/g, '');

    switch (format) {
      case THIS_WEEK:
        return moment(dateTime).format(DAY_FORMAT)
      case THIS_MONTH:
        return moment(dateTime).format(MONTH_FORMAT)
      case THIS_YEAR:
        return moment(dateTime).format(YEAR_FORMAT)
      default:
        return moment(dateTime).format(TODAY_FORMAT)
    }
  }

  const getDataResults = (filter, data) => {
    if (filter === TODAY || filter === THIS_WEEK) {
      return data.events;
    } else {
      return data;
    }
  }

  const countEventResults = (eventResults, filterToday, filterThisWeek, filterThisMonth, formattedDates, formattedData) => {
    let count;
    if (filterToday || filterThisWeek) {
      count = formattedDates.reduce((acc, value) => ({
        ...acc,
        [value]: (acc[value] || 0) + 1
      }), {});
    } else {
      let totalEvents = [];
      eventResults.forEach(item => {
        let exists = totalEvents.filter(event => {
          if (filterThisMonth) {
            return event.Day === item.Day;
          } else {
            return event.Month === item.Month;
          }
        });
        if (exists.length) {
          let existsIndex = totalEvents.indexOf(exists[0]);
          totalEvents[existsIndex].Total = totalEvents[existsIndex].Total + item.Total;
        } else {
          totalEvents.push(item);
        }
      });

      totalEvents.sort((a, b) => { return new Date(a.Year, a.Month, a.Day) - new Date(b.Year, b.Month, b.Day) });

      count = formattedData?.reduce((acc, value, index) => ({
        ...acc,
        [value] : totalEvents[index].Total
      }), {});
    }
    return count;
  }

  const handleRedirect = (value) => {
    history.push(`/${page}`);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleTypeFilter = (event) => {
    setTypeFilter(event.target.value);
    setPage(1);
  }

  const handleEventRange = (event) => {
    setEventsFilter(event.target.value);
    setPage(1);
  }

  const handleAccessFilter = (event) => {
    setAccessFilter(event.target.value);
    setPage(1);
  }

  const handleWSMessage = useCallback(() => {
    if (!hasControllerPermission) {
      return;
    }

    const topic = newTopic;
    const message = newMessage;

    setNewMessage({});
    setNewTopic('');

    if (topic !== WSTOPIC.CONTROLLER_STATUS) {
      if (topic === WSTOPIC.ERROR) {
        console.error("Error:", message, topic);
      }
      return;
    }

    const { controllerStatusDTO } = message;
    if (!controllerStatusDTO) {
      return;
    }

    const { serialNumber } = controllerStatusDTO;
    if (!serialNumber) {
      return;
    }

    const serialNumbers = Array.isArray(controllerStatus.serialNumber) ? controllerStatus.serialNumber : [];
    if (controllerStatusDTO.status === OFF) {
      if (serialNumbers.includes(serialNumber)) {
        return;
      }
      const newCounts = {
        ...controllerStatus,
        serialNumber: [...new Set([...serialNumbers, serialNumber])],
        count: controllerStatus.count + 1,
      };
      setControllerStatus(newCounts);
      return;
    }

    if (!serialNumbers.includes(serialNumber)) {
      return;
    }
    
    const newSerialNumber = serialNumbers.filter(number => number !== serialNumber);
    const newCounts = {
      ...controllerStatus,
      serialNumber: newSerialNumber,
      count: controllerStatus.count - 1,
    };
    setControllerStatus(newCounts);
  }, [controllerStatus, hasControllerPermission, newTopic, newMessage, setNewMessage, setNewTopic]);

  useEffect(() => {
    if (newTopic && Object.keys(newMessage).length !== 0) {
      handleWSMessage();
    }
  }, [handleWSMessage, newMessage, newTopic]);

  return (
    <>
      {
        administrator.roles.length ?
          <Container maxWidth="xl" className={classes.container}>
            <Title title={t('dashboard')}/>
            <Content
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              events={events}
              handleRedirect={handleRedirect}
              setEventsFilter={setEventsFilter}
              eventsFilter={eventsFilter}
              setTypeFilter={setTypeFilter}
              typeFilter={typeFilter}
              accessFilter={accessFilter}
              setAccessFilter={setAccessFilter}
              eventsData={eventsData}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              totalElement={totalElement}
              handleTypeFilter={handleTypeFilter}
              handleEventRange={handleEventRange}
              handleAccessFilter={handleAccessFilter}
              tableIsLoading={tableIsLoading}
              tableContentIsLoading={tableContentIsLoading}
              eventIsLoading={eventIsLoading}
              eventContentIsLoading={eventContentIsLoading}
              thickWidth={thickWidth}
              controllerStatus={controllerStatus}
              accessDeniedStatus={accessDeniedStatus}
              administrator={administrator}
              chartData={chartData}
              showToaster={showToaster}
              openSyncStatus={openSyncStatus}
              availableCredStatus={availableCredStatus}
              invalidPersonStatus={invalidPersonStatus}
              invalidCredStatus={invalidCredStatus}
              activeChip={activeChip}
              setActiveChip={setActiveChip}
              hasEventPermission={hasEventPermission}
              hasCredentialPermission={hasCredentialPermission}
              hasPersonPermission={hasPersonPermission}
              hasControllerPermission={hasControllerPermission}
            />
          </Container>
        :
          <Container maxWidth="xl" data-testid="noRolesAssignedContainer">
            <Error401 email={administrator.email}/>
          </Container>
      }
    </>
  );
}

export default Dashboard;